@import 'variables';

button,
.button {

  background-color: black;
  color: white;

  font-family: "Roboto-Bold";
  font-size: 16px;

  cursor: pointer;
  border: none;

  transition: color, background-color .3s ease-in;

  min-height: $gui-element-height;

  text-align: center;

  border-radius: 2em;

  white-space: break-spaces;
  padding: 10px 15px;
}

button.growBtn {
  width: 100%;
}

button.desktopGrowBtn {
  @media #{$desktop} {
    width: 100%;
  }
}

button.mobileGrowBtn {
  @media #{$mobile-and-tablet} {
    width: 100%;
  }
}

// sometimes you dont want a button (i.e. it submits nearest form)
// if you force a div to look like a button with this class -> align content in center
div.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// button colors
///////////////////////////////////////////////////////////////////////////////////////////////////

button.black-button,
.button.black-button,
.black-button {
  box-shadow: $standard-boxshadow;
  color: white;
  background-color: black;

  &:hover {
    background-color: white;
    color: black;
  }
}

button.btn-transparent-bg,
.button.btn-transparent-bg,
.btn-transparent-bg {
  background-color: transparent;
}

button.orange-button,
.button.orange-button,
.orange-button {
  box-shadow: $standard-boxshadow;
  color: white;
  background: $orangeButton;

  &:hover {
    background: $orangeButtonHover;
  }
}

button.transparent-orange-button,
.button.transparent-orange-button,
.transparent-orange-button {
  color: $orange1;
  background: transparent;
  border: 1px solid $orange1;

  &:hover {
    background: $orangeButtonHover;
    color: white;
  }
}

button.blue-button,
.button.blue-button,
.blue-button {
  box-shadow: $standard-boxshadow;
  color: white;
  background: $buttonblue;

  &:hover {
    background: $buttonblueHover;
  }
}

button.red-button,
.button.red-button,
.red-button {
  box-shadow: $standard-boxshadow;
  color: $background-grey;
  background: $red;

  &:hover {
    background: $dark-red;
  }
}

button.white-button,
.button.white-button,
.white-button {
  box-shadow: $standard-boxshadow;
  color: $background-dark;
  background: white;

  &:hover {
    background: $background-grey;
  }
}

button.dark-grey-button,
.dark-grey-button {
  box-shadow: $standard-boxshadow;
  color: $background-grey;
  background: $background-dark;

  &:hover {
    background: #121416;
  }
}

button.light-grey-button,
.light-grey-button {
  box-shadow: $standard-boxshadow;
  color: black;
  background: lightgray;

  @media #{$desktop} {
    &:hover {
      background: #121416;
      color: white;
    }
  }
}
