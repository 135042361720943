@import 'variables';

.plain-expansion-accordion.mat-accordion {
  .mat-expansion-panel {
    box-shadow: none;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
    background-color: transparent;
  }

  .category-expander {
    display: flex;
    width: calc(100% - 35px);
    height: 35px;
    margin-left: 0px;
    &:hover {
      color: black;
    }
  }

  .category-children {
    padding-left: 10px; // hardcoded
    max-width: 100%;
    &.second {
      padding-left: 15px;
      border-left: 2px solid;
      border-left: 3px solid #e9ecef;
    }
  }

  .category-children mat-expansion-panel-header {
    border-left: 3px solid #c4c4c4;
  }

  .category-children .category-expander {
    margin-left: 15px;
  }

  mat-expansion-panel-header {
    max-height: $gui-element-height;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    outline: none;
    cursor: pointer;
    border-radius: 0px;

    &.selected .category-name {
      font-weight: bold;
    }

    .category-name {
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
      padding-right: 35px;
      max-width: 150px;
    }

    .category-child{
      padding: 0px 10px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  .mat-expansion-indicator {
    &:hover:after {
      color: black;
      font-weight: bold;
    }

    position: absolute;
    right: 0px;
    top: 0px;
    width: $gui-element-height;
    height: $gui-element-height;

    &:after {
      left: calc(50% - 4px);
      top: calc(50% - 6px);
      position: absolute;
    }
  }

  .mat-expansion-panel-body {
    padding: 0px;
  }
}

/*
  orange-panel
*/
mat-expansion-panel.orange-panel {

  background-color: $orange1;

  // magic number because of safari bug
  border-radius: 20px;
  
  transition: border-radius .1s;

  box-shadow: $standard-boxshadow;
  min-height: $gui-element-height;

  .mat-expansion-panel-header {
    color: white;
    font-family: "Roboto-Bold";
    font-size: 16px !important;
    line-height: 32px;
    padding: 12px;
    box-sizing: border-box;
    height: $gui-element-height;

    mat-panel-title.mat-expansion-panel-header-title{
      display: block;
      text-align: center;
    }
  }

  .mat-expansion-panel-body {
    background-color: white;
    color: black;
    padding: 0 16px 16px;

    .entry {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 0px;
      cursor: pointer;

      i {
        font-size: 22px;
      }
    }
  }

  &.mat-expanded{
    border-radius: $small-border-radius;
  }
}

/*
  orange-caret-uppercase-panel
*/
mat-expansion-panel.orange-caret-uppercase-panel {
  .mat-expansion-panel-header {
    text-transform: uppercase;
    border-bottom: 1px solid $orange1;
    font-size: 16px;
    padding: 12px 0px;
    line-height: 36px;

    .mat-expansion-indicator {
      color: $orange1;
      padding: 0px 15px;

      &::after {
        padding: 9px;
        border-width: 0 1px 1px 0px;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 12px 0px;
    font-size: 16px;
    line-height: 29px;
    color: #666666;
  }
}

@media #{$desktop}{

  mat-expansion-panel.orange-caret-uppercase-panel{
    .mat-expansion-panel-header {
      font-size: 30px;
    }
  }

}