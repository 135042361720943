@import 'variables';

// used for content preview component
.previewCard.middle {
  --card-height: 600px;
}

.previewCard {

  --card-height: 484px;

  $card-width: auto;
  $card-image-height: 249px;

  $card-content-height: calc(var(--card-height) - #{$card-image-height});
  $card-content-padding: 14px;

  box-shadow: $standard-boxshadow;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;

  height: var(--card-height);
  width: $card-width;

  border-radius: $small-border-radius;

  .list-item-image {
    height: $card-image-height;
    background-position: center;
    background-size: cover;

    border-bottom: 1px solid rgba(0, 0, 0, .2);

    -moz-border-radius-topleft: $small-border-radius;
    -moz-border-radius-topright: $small-border-radius;
    border-top-left-radius: $small-border-radius;
    border-top-right-radius: $small-border-radius;

    .triangle {
      position: absolute;
      top: 0px;
      right: 0px;

      height: 32px;
      width: 180px;

      color: white;
      background-color: black;

      display: flex;
      align-items: center;
      justify-content: center;

      border-top-right-radius: $small-border-radius;
      border-bottom-left-radius: $bigger-border-radius1;
      -moz-border-radius-topright: $small-border-radius;
      -moz-border-radius-bottomleft: $bigger-border-radius1;
    }

    iframe {
      -moz-border-radius-topleft: $small-border-radius;
      -moz-border-radius-topright: $small-border-radius;
      border-top-left-radius: $small-border-radius;
      border-top-right-radius: $small-border-radius;
    }
  }

  .content {
    height: $card-content-height;
    padding: $card-content-padding;
    overflow: hidden;
    word-break: break-word;

    border-bottom-left-radius: $small-border-radius;
    border-bottom-right-radius: $small-border-radius;
    -moz-border-radius-bottomleft: $small-border-radius;
    -moz-border-radius-bottomright: $small-border-radius;

    .location {
      font-family: "Roboto-Light";
      font-size: 16px;
      line-height: 21px;
      color: #666666;
    }

    .title {
      font-family: "Roboto-Medium";
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
    }

    .teaser {
      font-family: "Roboto-Light";
      font-size: 16px;
      line-height: 21px;
      color: #666666;
    }

    .fadeout {
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      height: 20px;
      position: absolute;
      width: 100%;
      bottom: 0px;
    }
  }
}