@import 'variables';

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.black-bottom-dialog {
  max-width: 100vw !important;
}

.black-centered-dialog {
  background-color: black;

  color: white;

  // material is terror...
  // app-login-dialog {
  //   height: 100%;
  //   width: 100%;
  //   display: block;
  mat-dialog-content {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    .dialog {

      height: 100%;
      width: 100%;

      color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      i.closex {
        position: absolute;
        right: 2vw;
        top: 2vh;

        cursor: pointer;

        font-size: 45px;
        color: white;
      }

      .dialog-content {

        img {
          width: 200px;
          height: 200px;
        }

        h2 {
          margin-top: 40px;
          margin-bottom: 30px;

          font-family: "Roboto-Medium";
          font-size: 58px;
          line-height: 88px;

          text-align: center;
        }

        .switch {

          margin-bottom: 40px;

          .switchbtn {
            color: $orange1;
            cursor: pointer;
          }
        }

        .line {
          margin: 25px 0px;
        }

        input {
          border-radius: $standard-border-radius;
          background-color: transparent;
          border: 1px solid $orange1;
          color: white;
          min-height: 57px;
        }
      }
    }
  }
}

// black dialog desktop
@media #{$desktop} {
  .black-centered-dialog {
    mat-dialog-content {

      height: unset;
      width: unset;
      max-height: unset;
      max-width: unset;

      .dialog {

        height: unset;
        width: unset;

        .dialog-content {
          width: 35vw;
        }
      }
    }
  }
}

@media #{$mobile-only} {

    .black-centered-dialog.mobile-fullscreen {
        height: 100vh !important;
        width: 100vw !important;

        max-height: unset !important;
        max-width: unset !important;

        mat-dialog-content {
            margin: 0px;
        }

        // This is pretty hacky, but I have no clue why the mat-dialog-content
        // element has a style with a -24px margin on small screens. Using
        // ng-deep to overwrite the mat-dialog-content style didn't work,
        // because that selector was at least deprecated an probably already
        // removed in our current angular version.
        // So I decided to just set a variable that can be applied in the
        // dialog component itself. For normal sizes this value is just not
        // set, so it seems to be ignored.
        // I'm not happy about this, but I can't be bothered to spend more time
        // fighting this style and not finding any documentation about it.
        // Probably I'm just overlooking something totally obvious.
        --mobile-fullscreen-overwritten-dialog-margin: 0px;
    }

}
