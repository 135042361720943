@import 'variables';

mat-horizontal-stepper.createStepper {

  background-color: white;

  border-radius: $small-border-radius !important;
  border: 2px solid $xdLightGreyColor;

  // header
  .mat-horizontal-stepper-header-container {
    background-color: white;
    color: lightgray;
    border-radius: $small-border-radius !important;

    .mat-step-header {

      // icons
      .mat-step-icon {
        font-family: "Roboto-Bold";
        //padding: 6px;
      }

      // done icons
      .mat-step-icon.mat-step-icon-selected,
      .mat-step-icon.mat-step-icon-state-edit {
        background-color: $orange1;
        color: white;

        mat-icon {
          color: white;
        }
      }

      // labels
      .mat-step-label .mat-step-text-label {
        font-size: 16px;
      }

      // selected label
      .mat-step-label.mat-step-label-active.mat-step-label-selected {
        color: $orange1;
        font-size: 16px;
        font-family: "Roboto-Medium";
      }
    }
  }

  // container
  .mat-horizontal-content-container {
    padding: 0px $padding-default $padding-default $padding-default;

    .mat-horizontal-stepper-content {
      .step-selector-container-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
      }

      .step-selector-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .step-selector-top-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          padding-top: 50px;
          padding-bottom: 50px;

          .step-selector-top {
            width: 100%;
            align-self: flex-start;

            .title {
              color: $orange1;
              padding-bottom: 20px;
              font-family: "Roboto-Medium";
              letter-spacing: 0.8px;
            }
          }
        }

        .step-selector-middle-container {
          display: flex;
          flex-direction: row;
          justify-content: center;

          .step-selector-middle {
            .fat1 {
              font-size: 30px;
              letter-spacing: 0.6px;
              padding-bottom: 20px;
            }
          }

          &:has(app-category-selection) {
            flex-direction: column;
          }
        }

        .step-selector-bottom-container {
          display: flex;
          width: 100%;
          justify-content: end;
        }
      }
    }
  }

  .fieldHeadline {
    font-weight: bold;
  }

  .fat1 {
    font-family: "Roboto-Bold";
  }

  editor .tox.tox-tinymce {
    border-radius: $standard-border-radius;
  }
}

::ng-deep .mat-horizontal-stepper-header {
  pointer-events: none !important;
}

@media #{$mobile-and-tablet} {
  mat-horizontal-stepper.createStepper {
    .mat-horizontal-stepper-header-container {
      .mat-step-header {
        width: 4vw;
      }
    }

    editor {
      width: 75vw;
    }
  }
}

@media #{$desktop} {
  form:has(mat-horizontal-stepper.createStepper) {
    width: 100%;
  }

  mat-horizontal-stepper.createStepper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .mat-horizontal-stepper-header-container {
      align-self: center;
      width: 80%;
    }

    .mat-horizontal-content-container {
      display: flex;
      flex-direction: column;

      .mat-horizontal-stepper-content {
        .step-selector-container {
          width: 55%;

          .step-selector-top-container {
            .step-selector-top {
              .title {
                text-align: unset;
                font-size: 40px;
                color: $xdDesignBlack;
              }

              .subtitle {
                text-align: unset;
                letter-spacing: 0.36px;
                font-family: "Roboto-Regular";
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}