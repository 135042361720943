@import 'variables';

.displaycard {

  box-shadow: $standard-boxshadow;
  padding: 45px $padding-default $padding-default $padding-default;
  position: relative;
  background-color: white;

  border-radius: $small-border-radius;

  .title {
    line-height: 34px;
    font-size: 22px;
    font-family: "Roboto-Medium";
  }

  .teaser {
    white-space: pre-line;
  }

  .triangle {
    position: absolute;
    top: 0px;
    right: 0px;

    height: 32px;
    width: 180px;

    color: white;
    background-color: black;

    display: flex;
    align-items: center;
    justify-content: center;

    border-top-right-radius: $small-border-radius;
    border-bottom-left-radius: $bigger-border-radius1;
    -moz-border-radius-topright: $small-border-radius;
    -moz-border-radius-bottomleft: $bigger-border-radius1;
  }
}

@media #{$desktop} {
  .displaycard {
    padding: $padding-medium;
    .title {
      font-size: 28px;
    }
  }
}