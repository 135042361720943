@import 'variables';

.headline {
  font-size: 18px;
  margin-bottom: 12px;
}

.about-cube {
  -webkit-box-shadow: $standard-boxshadow;
  -moz-box-shadow: $standard-boxshadow;
  box-shadow: $standard-boxshadow;

  background-color: white;

  border-radius: $small-border-radius;

  .aboutme-content {
    padding: $padding-content-mobile;
  }

  @media #{$desktop} {
    .aboutme-content {
      padding: $padding-default $padding-large;
    }
  }

}

.infoblock {
  background-color: white;
  box-shadow: $standard-boxshadow;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 14px;

  .label {
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .multi_line_text {
    white-space: pre-line;
  }
}
