@import 'variables';

.black-bottom-dialog {

  background-color: black;
  color: white;

  mat-dialog-content {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    .dialog {

      // i.closex {
      //   position: absolute;
      //   right: 2vw;
      //   top: 2vh;

      //   cursor: pointer;

      //   font-size: 45px;
      //   color: white;
      // }

      .dialog-content {}
    }
  }
}