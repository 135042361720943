@import 'variables';

.black-fullscreen-dialog {

  background-color: black;
  color: white;

  mat-dialog-content {

    /*
      this is not enough
      dont forget:
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      in .ts MatDialog.open()
    */
    height: 100%;
    max-height: 100%;

    .dialog {

      position: relative;
      color: white;

      i.closex {
        position: absolute;
        right: 0px;
        top: 0px;

        cursor: pointer;

        font-size: 45px;
        color: white;
      }

      .dialog-content {

        .switch {

          margin-bottom: 40px;

          .switchbtn {
            color: $orange1;
            cursor: pointer;
          }
        }

        .line {
          margin: 25px 0px;
        }

        input {
          border-radius: $standard-border-radius;
          background-color: transparent;
          border: 1px solid $orange1;
          color: white;
          min-height: 57px;
        }
      }
    }
  }

  // }
}

// black dialog desktop
@media #{$desktop} {
  .black-fullscreen-dialog {
    mat-dialog-content {
      .dialog {
        .dialog-content {
          width: 35vw;
        }
      }
    }
  }
}