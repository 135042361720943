@import 'variables';

table.orange-table {
  th{
    color: $orange1;
    border-bottom: 1px solid $orange1;
    border-right: 1px solid $orange1;
    text-align: left;
    height: 60px;
    font-family: "Roboto-Light";
    font-size: 16px;
  }
  th:last-child{
    border-right: 0px;
    padding: 0px 10px;
  }

  td{
    border-right: 1px solid $orange1;
    font-family: "Roboto-Light";
    font-size: 16px;
    line-height: 29px;
    color: #666666;
    padding: 5px;
  }
  td:last-child{
    border-right: 0px;
  }

  th.centered, td.centered{
    text-align: center;
  }

  &,th,td{
    border-collapse: collapse;
  }
}

@media #{$desktop}{
  table.orange-table {
    th{
      font-size: 19px;
    }

    td{
      font-size: 19px;
    }
  }
}