@import 'variables';

.darkgray-centered-dialog {

  background-color: #212429;
  color: white;

  mat-dialog-content {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    .dialog {

      height: 100%;
      width: 100%;

      color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      i.closex {
        position: absolute;
        right: 2vw;
        top: 2vh;

        cursor: pointer;

        font-size: 45px;
        color: white;
      }

      h2 {
        line-height: 36px;
        color: white;

        font-family: "Roboto-Medium";
        font-size: 24px;
        line-height: 32px;

        text-align: center;
      }

      input {
        border-radius: $standard-border-radius;
        background-color: transparent;
        border: 1px solid $orange1;
        color: white;
        min-height: 57px;
      }
    }
  }
}

// desktop
@media #{$desktop} {
  .darkgray-centered-dialog {
    mat-dialog-content {
      .dialog {
        width: 35vw;
      }
    }
  }
}