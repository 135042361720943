@use '@angular/material' as mat;

@import 'variables';

@import "node_modules/ngx-sharebuttons/themes/default";

////////////////////////////////////////////////////////////////////////////////////////////////
// fontfaces
////////////////////////////////////////////////////////////////////////////////////////////////
// TODO FIX FONT FAMILIES CHECK NEW METHOD TO DOWNLOAD FROM GOOGLE

/* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEz0dL-vwnYh2eg.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEzQdL-vwnYh2eg.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEzwdL-vwnYh2eg.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEzMdL-vwnYh2eg.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEz8dL-vwnYh2eg.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEz4dL-vwnYh2eg.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEzAdL-vwnYg.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc3CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc-CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc2CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc5CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc1CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc0CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjASc6CsTYl4BO.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xFIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xMIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xEIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xLIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xHIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xGIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc3CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc-CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc2CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc5CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc1CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc0CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ACc6CsTYl4BO.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic3CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic-CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic2CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic5CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic1CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic0CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBic6CsTYl4BO.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc3CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc-CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc2CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc5CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc1CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc0CsTYl4BOQ3o.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBCc6CsTYl4BO.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxFIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxEIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxLIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxHIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxGIzIXKMnyrYk.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfCRc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfABc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfCBc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfBxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfCxc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfChc4AMP6lbBP.woff2) format('woff2');
//   unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }


@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

////////////////////////////////////////////////////////////////////////////////////////////////
// angular material typography
////////////////////////////////////////////////////////////////////////////////////////////////

$my-typography: mat.define-typography-config($body-1: mat.define-typography-level($font-family: "Roboto-Regular",
      $font-size: 16px,
      $line-height: 24px,
      $letter-spacing: normal,
    ),

    // h1
    $headline: mat.define-typography-level($font-family: "Roboto-Bold",
      $font-size: 20px,
      $line-height: 1,
      $letter-spacing: normal,
    ),


    $title: mat.define-typography-level($font-family: "Roboto-Bold",
      $font-size: 20px,
      $line-height: 24px,
      $letter-spacing: normal,
    ),

    $subheading-2: mat.define-typography-level($font-family: "Roboto-Bold",
      $font-size: 18px,
      $line-height: 1,
      $letter-spacing: normal,
    ),

  );

@include mat.core($my-typography);






/* global default values */

html,
body {

  font-family: "Roboto-Regular";
  font-size: 16px;

  color: $mainFontColor;
  margin: 0;
  position: relative;
  background-color: $background-grey;
  line-height: 24px;

  // background image is set historically in header.component.ts
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left 0px top 0px;
}

body.nonscroll {
  overflow: hidden;
}

h1,
h2,
h3 {
  font-weight: bold;
  margin-top: 0px;
}

////////////////////////////////////////////////////////////////////////////////////////////////
// layout classes
////////////////////////////////////////////////////////////////////////////////////////////////
.threecol {
  .main-col {

    // min-height: calc(100vh - $navbar-height-mobile - 660px);

    padding-left: $padding-content-mobile;
    padding-right: $padding-content-mobile;

    h2.listHeadline {
      color: black;
      text-align: center;
      font-size: 19px;
    }

    h1.mainheadline {
      font-family: "Roboto-Bold";
      font-size: 28px;
      letter-spacing: 3.2px;
      text-transform: uppercase;
      margin-bottom: 0px;

      @media #{$desktop} {
        font-size: 70px;
      }
    }

    &.withTopPadding {
      padding-top: 20px;
    }

    &.withBottomPadding {
      padding-bottom: 40px;
    }

    @media #{$desktop} {

      // min-height: calc(100vh - $navbar-height - 480px);

      padding-left: 0px;
      padding-right: 0px;
      width: $desktop_main_col_width;
    }
  }

  .main-col.noSlider {
    padding-top: 30px;
    padding-bottom: 40px;

    @media #{$desktop} {
      padding-top: 80px;
    }
  }
}

/*
  GLOBAL HELPER CLASSES
  these are for ease of access - like a small flex class etc
*/
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.form-validation-error {
  color: red;
  font-size: 16px;
  padding: 10px 0px;
}

@media #{$mobile-and-tablet} {
  .hideTextMobile {
    display: none;
  }
}

.click-login-wall {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: $zindex_loginclickwall;
}

.min-menu-wrapper {
  min-width: 240px;
  margin-right: 20px;
}

.mat-chip-list-wrapper {
  background: #E9ECEF;
}

.overflow-hidden {
  overflow: hidden;
}

.flex {
  display: flex;
}

/*
  GLOBAL CUSTOM CLASSES
  these are bigger global classes that should be components :) 
*/
.tinyheader {
  background-color: orange;
  color: white;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 16px;
  padding: 6px;
}

.upload-overlay {
  cursor: pointer;

  .upload {
    display: none;
  }

  &:hover .upload {
    width: 100%;
    text-align: center;
    display: block;
    padding-top: calc(50% - 15px);

    i {
      background: gray;
      border-radius: 50%;
      padding: 5px;
    }
  }
}

/*
  NGX-GALLERY OVERRIDES
*/
ngx-gallery {

  .ngx-gallery-bullet {
    background: black !important;

    &.ngx-gallery-active {
      background: $orange1 !important;
    }
  }

  .ngx-gallery-arrow {
    background: white;
    color: black !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px 10px;
    font-size: 16px !important;

    &:hover {
      color: $orange1 !important;
    }
  }

  .ngx-gallery-thumbnail.ngx-gallery-active {
    border: 1px solid black;
  }

  .ngx-gallery-thumbnail:not(.ngx-gallery-active) {
    border: 1px solid white;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////
// material theme overwrites
////////////////////////////////////////////////////////////////////////////////////////////////

.mat-form-field-label {
  line-height: $gui-element-height !important;
}

.mat-select-trigger {
  height: unset;
}

.cdk-overlay-container {
  z-index: $zindex_dialog !important;
}

.mat-error {
  margin-top: 10px;
  color: red;
  font-family: "Roboto-Bold";
}

.align_label_with_mat_formfield {
  padding: .4375em 0;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(1) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: black !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  height: 32px;
  line-height: 32px;
  left: 10px;
  color: $placeholder-color;
  transition: all 0.4s;
  opacity: 1;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  opacity: 0;
}

.mat-form-field-infix {
  border-top: 0px;
}

.mat-select-value-text,
.mat-select-placeholder {
  line-height: $gui-element-height;
  height: $gui-element-height;
}

.mat-select-value-text {
  padding-left: $padding-small;
}

.mat-select-panel {
  background-color: white;
  box-shadow: $standard-boxshadow;

  mat-option:hover {
    background-color: lightgray;
  }
}

.mat-autocomplete-panel {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: white;
  box-shadow: $standard-boxshadow;

  mat-option:hover {
    background-color: lightgray;
  }
}

.mat-select-arrow {
  color: black;
}

.mat-checkbox-frame {
  border: none;
  background-color: #c6c6c6;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $selectedorange;
}

.mat-checkbox-checkmark {
  fill: black;
}

.mat-checkbox-checked .mat-checkbox-checkmark-path,
.mat-checkbox-checkmark-path {
  stroke: white !important;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: #c6c6c6;
}

mat-footer-row,
mat-header-row,
mat-row {
  border: none;
}

mat-cell:first-of-type,
mat-footer-cell:first-of-type,
mat-header-cell:first-of-type {
  padding-left: 0px;
}

mat-cell .mat-form-field {
  width: calc(100% - 15px);
}

// mat slide toggle

// unchecked
.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: lightgray;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: gray;
}

// checked
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: gray;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: black;
}

td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
  padding-right: 0px;
  padding-left: 0px;
}

.mat-field-no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.small-field .mat-form-field-infix {
  width: auto;
  min-width: 80px;
  max-width: 100px;

  .mat-input-element {
    max-width: calc(100% - 10px);
  }
}

.inline-field .mat-form-field-infix {
  padding: 4.5px;
  margin-left: 10px;
}

// hide mat stepper labels on small screens as its not responsive
@media #{$md-and-down} {
  .mat-step-label {
    display: none;
  }
}

/* sentry overrides */
.sentry-error-embed-wrapper {
  .sentry-error-embed {
    header {
      p {
        font-size: 18px;
        color: #394359;
      }
    }
  }
}

.full-width-input {
  width: 100%;

  mat-form-field {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-tooltip {
  font-size: 16px;
}

// ngx loading above angular material dialog
ngx-loading {
  .backdrop {
    z-index: $zindex_loading_backdrop !important;
  }

  .spinner-three-bounce {
    z-index: $zindex_loading_spinner !important;
  }
}

// these are the small round "i" info buttons with tooltip
i.infobutton {
  color: lightgray;
  font-size: 20px;

  -webkit-transition: color .2s ease-in;
  -moz-transition: color .2s ease-in;
  -o-transition: color .2s ease-in;
  transition: color .2s ease-in;

  &:hover {
    color: black;
  }
}

.break-word {
  word-break: break-word;
}

.noBreadcrumbSpacer {
  padding-top: 60px;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// inputs
///////////////////////////////////////////////////////////////////////////////////////////////////

// main styling
input,
input.mat-input-element,
select,
select:focus,
mat-select,
textarea.zimTextarea,
textarea.mat-input-element {

  background-color: white;
  color: black;

  height: $gui-element-height;
  border: 1px solid #ccc;
  border-radius: $small-border-radius;

  // fixed fontsize for all inputs so apple does not zoom them
  font-size: 16px;
}

// everything except mat-select shall have text indent
input,
input.mat-input-element,
select,
select:focus,
textarea.zimTextarea,
textarea.mat-input-element {
  text-indent: 10px;
}

select:focus {
  outline-color: black;
}

// disable spinner buttons in number inputs
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// no blue border around inputs
input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// hide tinymce api key warning 
.tox-notification {
  display: none !important;
}