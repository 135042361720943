/* global variables */

// colors

$grey1: #818181;
$grey2: #333333;
$grey3: #e9ecef;
$grey4: #d3d3d3;
$orange1: #ff8d00;
$dark-orange1: #cc7700;
$green: #2A8601;
$red: #cc0101;
$dark-red: #860101;

$blue1: #2078f4;
$blue1darker: #1a62c8;

$mainFontColor: black;
$fontgray: $grey2;
$font-light-gray: $grey1;

$background-grey: #f2f2f2;
$background-dark: #212529;
$dark-grey: #232429;
$light-grey: #4f5054;
$very-light-grey: $grey1;
$white-opaque: rgba(255, 255, 255, 0.7);
$black-opaque: rgba(0, 0, 0, 0.5);
$font-grey: #888;
$placeholder-color: rgba(0, 0, 0, 0.5);

$selectedorange: $orange1;
$orangeButton: $orange1;
$orangeButtonHover: $dark-orange1;
$buttonblue: $blue1;
$buttonblueHover: $blue1darker;

// padding
$padding-tag: 5px;
$padding-small: 10px;
$padding-default: 20px;
$padding-medium: 40px;
$padding-large: 60px;
$padding-content: 400px;

$padding-dialogs-mobile: 16px;
$padding-content-mobile: 16px;

// element sizes
$gui-element-height: 40px;
$full-width-image-height: 200px;
$navbar-height: 94px;
$navbar-height-mobile: 50px;

$header-imageslider-height-desktop: 335px;
$header-imageslider-height-mobile: 230px;

$avatar-width: 75px;
$post-category-width: 100px;
$post-category-height: 25px;

// misc
$standard-boxshadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
$boxshadow-highlight: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);

$small-border-radius: 10px;
$standard-border-radius: 14px;
$bigger-border-radius1: 38px;

$round-icon-size: 45px;

// font
$h1: 30pt;
$h1-mobile: 16pt;
$h2: 22pt;
$h2-mobile: 16pt;
$h3: 20pt;
$h4: 16pt;

////////////////////////////////////////////////////////////////////////////////////////////////
// media-queries
////////////////////////////////////////////////////////////////////////////////////////////////

/*
  These should NOT be used anymore.
  And should be phased out asap.
*/
$xs: "only screen and (max-width: 600px)";
$sm: "only screen and (min-width: 601px) and (max-width: 960px)";
$sm-and-up: "only screen and (min-width: 601px)";
$sm-and-down: "only screen and (max-width: 960px)";
$md: "only screen and (min-width: 960px) and (max-width: 1280px)";
$md-and-up: "only screen and (min-width: 960px)";
$md-and-down: "only screen and (max-width: 1280px)";
$lg: "only screen and (min-width: 1281px) and (max-width: 1919px)";
$lg-and-up: "only screen and (min-width: 1281px)";
$xl-and-up: "only screen and (min-width: 1920px)";

/*
  These and the breakpoints of fxLayout are what we use.
*/
$breakpoint_mobile_min: 0px;
$breakpoint_mobile_max: 659px;
$breakpoint_tablet_min: 660px;
$breakpoint_tablet_max: 1279px;
$breakpoint_desktop_min: 1280px;
// $breakpoint_desktop_max: endless
$mobile-only: "only screen and (max-width: #{$breakpoint_mobile_max})";
$mobile-and-tablet: "only screen and (max-width: #{$breakpoint_tablet_max})";
$tablet: "only screen and (max-width: #{$breakpoint_tablet_max}) and (min-width: #{$breakpoint_tablet_min})";
$tablet-and-up: "only screen and (min-width: #{$breakpoint_tablet_min})";
$desktop: "only screen and (min-width: #{$breakpoint_desktop_min})";

////////////////////////////////////////////////////////////////////////////////////////////////
// z-indices
////////////////////////////////////////////////////////////////////////////////////////////////

$zindex_dsgvobanner: 9970;

$zindex_loading_spinner: 9961;
$zindex_loading_backdrop: 9960;

// dialogs
$zindex_dialog: 9950;

// mobile menu
$zindex_notifications_bell_tooltip: 9931;
$zindex_notifications_bell_tooltip_outsideclickwall: 9930;

$zindex_mobilemenu: 9912;
$zindex_mobilenavbar: 9910;

$zindex_header_menu_internals: 9899;
$zindex_profilemenu_tooltip: 9898;
$zindex_logo: 9897;

$zindex_loginclickwall: 9700;

$zindex_header_create_tooltip: 9003;
$zindex_header_arrows: 9002;
$zindex_header_linkplane: 9001;
$zindex_header: 9000;

$zindex_content_plus_2: 5002;
$zindex_content_plus_1: 5001;
$zindex_content: 5000;

$zindex_hidden: -1;

$xdDesignBlack: #111213;
$xdGreyColor: #5e636e;
$xdLightGreyColor: #c2c9d6;

$desktop_main_col_width: 1200px;
